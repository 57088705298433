import { isEqual } from 'underscore';
import { get } from 'vuex-pathify';
import { formatDateForAPI } from '~/util/apiDateFormat';

const props = {};

const data = () => {
  return {
    loading: false,
  };
};

const computed = {
  advertiser: get('common/advertiser'),
  universalPixelId: get('common/universalPixelId'),
  account: get('common/account'),
  dates: get('dashboard/dates'),
  mediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
  selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
  selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
  selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
  selectedIOIds: get('dashboard/GET_SELECTED_IO_IDS'),
  selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
  showUniques: get('dashboard/filters@showUniques'),
  attribution: get('dashboard/filters@attribution'),
  methodology: get('dashboard/filters@methodology'),
  conversionWindow: get('dashboard/filters@conversionWindow'),
  selectedAdGrpOptStr: get('dashboard/GET_SELECTED_AD_GROUP_STR'),

  payload() {
    const result = {
      advertiser: this.advertiser ? this.advertiser.name : '',
      client: this.account ? this.account.name : '',
      xandrId: this.advertiser ? this.advertiser.xandrAdvertiserId : '',
      mediaType: this.mediaTypes,
      ioIds: this.selectedIOIds,
      endDate: formatDateForAPI(this.dates.endDate),
      startDate: formatDateForAPI(this.dates.startDate),
      pixel: this.universalPixelId,
      event: this.selectedEvent.eventLabel,
      category: this.selectedEvent.category,
      subCategory: this.selectedEvent.subCategories,
      numSubCategories: this.selectedEvent.numSubCategories,
      showUniques: this.showUniques,
      attribution: this.attribution,
      methodology: this.methodology,
      conversionWindow: this.conversionWindow,
      adGroups: this.selectedAdGrpOptStr,
      audience: this.selectedAudiences,
      publisher: this.selectedPublishers,
      creative: this.selectedCreatives,
    };
    return result;
  },
};

const watch = {
  payload: {
    deep: true,
    async handler(n, o) {
      // This check prevents false positive updates from parent component for updates on "initialPayload" prop.
      if (isEqual(n, o)) {
        return;
      }

      if (n.advertiser === o.advertiser) {
        try {
          // this function should be implemented by the component/mixin using this mixin
          await this.loadGraphData(n);
        } catch (err) {
          console.error('Error in updating graph view chart ->', err.message, err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      }
    },
  },

  // This check prevents false positive updates from parent component for updates on "initialPayload" prop.
};

export default { computed, data, props, watch };
